import { Suspense } from 'react'
import type { ReactElement } from 'react'
import { RouterProvider } from 'react-router-dom'

import routes from './routes'

const Router = (): ReactElement => {
    return (
        <Suspense
            fallback={
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                ></div>
            }
        >
            <RouterProvider router={routes} />
        </Suspense>
    )
}

export default Router
