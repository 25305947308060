import type { ReactElement } from 'react'
import { isRouteErrorResponse, useRouteError } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { isProduction } from 'helpers/environment'
import ErrorPage from 'pages/ErrorPage'
import useQueryParams from 'hooks/useQueryParams'

import notFoundSVG from 'assets/images/not-found.svg'
import serverErrorSVG from 'assets/images/server-error.svg'
import GenericError from 'Errors/GenericError'

declare global {
    interface Window {
        newrelic: any
    }
}

const logError = (error: any, attribute: { [key: string]: any }): void => {
    if (isProduction()) {
        window.newrelic?.noticeError(error, attribute)
    } else {
        console.error(error)
    }
}

const ErrorBoundary = (props: any): ReactElement => {
    const { t } = useTranslation()
    const error = useRouteError()
    const { tref, reference_id } = useQueryParams()

    logError(error, {
        tref: tref ?? '',
        reference_id: reference_id ?? '',
        url: window.location.href,
    })
    if (isRouteErrorResponse(error)) {
        if (error.status === 404) {
            return (
                <ErrorPage
                    title={t('errors.404.title')}
                    description={t('errors.404.description')}
                    image={notFoundSVG}
                />
            )
        }
    }

    if (error instanceof GenericError) {
        return (
            <ErrorPage
                title={error.title}
                description={error.description}
                image={error.image}
            />
        )
    }

    return (
        <ErrorPage
            title={t('errors.500.title')}
            description={t('errors.500.description')}
            image={serverErrorSVG}
        />
    )
}

export default ErrorBoundary
