import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { isProduction } from 'helpers/environment'

i18n
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        debug: false,
        fallbackLng: 'id',
        resources: {
            en: {
                translation: {
                    greeting: 'Hello!',
                    labelPrivacyPolicy: 'Privacy Policy',
                    labelTermsAndConditions: 'Terms and Conditions',
                    appSelection: {
                        heading: {
                            payment: 'Choose app to pay',
                            linking: 'Link with which app?',
                        },
                        gopay: {
                            title: 'GoPay app',
                            description:
                                "You'll be redirected to download the app first",
                            redirectCta: 'Go to Gopay app',
                        },
                        gojek: {
                            title: 'Gojek app',
                            redirectCta: 'Go to Gojek app',
                        },
                        savePreferenceDescription:
                            'Always use the app I choose',
                        redirectPaymentDescription:
                            'Redirecting you to complete payment',
                        redirectLinkingDescription:
                            'Redirecting you to complete linking',
                        redirectPaymentAlert:
                            'If nothing happens, choose app below to continue with your payment',
                        redirectLinkingAlert:
                            'If nothing happens, choose app below to continue with your linking',
                        or: 'or',
                        redirectionDescriptionGopayApp:
                            'If nothing happens, click <redirectionLink>here.</redirectionLink>',
                        gopayAppOneTimePaymentRedirectionTitle:
                            'Redirecting you to GoPay app to complete payment',
                    },
                    changePreference: {
                        successDescription: 'Preference changed successfully',
                        errorDescription: 'No change in app preference',
                        redirectDescription:
                            'Redirecting you back to the app...',
                    },
                    resetPreference: {
                        gopay: {
                            title: 'Your current default payment app is GoPay',
                            description:
                                'To change your default app to Gojek, please uninstall your GoPay app and click the button below.',
                            cta: 'Change default app to Gojek',
                        },
                        gojek: {
                            title: 'Your current default payment app is Gojek',
                            description:
                                'To change your default app to GoPay, please install GoPay app. Once you install GoPay, your preference will be automatically changed to GoPay.',
                            cta: 'Back to Gojek app',
                        },
                        none: {
                            title: 'Your payment app preference is not set',
                            description: `To set your preference, please make payment on online merchant.

                            For the time being, we’ll redirect you back to Gojek app.`,
                            cta: 'Back to Gojek app',
                        },
                    },
                    resetPreferenceSuccess: {
                        title: 'Default app has been set to Gojek',
                        description: 'Redirecting you back to Gojek app...',
                        buttonTitle: 'Back to Gojek app',
                        buttonDescription:
                            'If nothing happens, tap the button below',
                    },
                    errors: {
                        404: {
                            title: "It should be here but it's not",
                            description:
                                "We're not sure why either. But, we're sure that you can try linking again.",
                        },
                        500: {
                            title: 'We got a little hiccup',
                            description:
                                "Don't worry, our team is looking into it. We'll be back for you soon!",
                        },
                    },
                },
            },
            id: {
                translation: {
                    greeting: 'Halo!',
                    labelPrivacyPolicy: 'Kebijakan pribadi',
                    labelTermsAndConditions: 'Syarat dan ketentuan',
                    appSelection: {
                        heading: {
                            payment: 'Silakan pilih app buat bayar',
                            linking: 'Mau hubungin pake app apa?',
                        },
                        gopay: {
                            title: 'Aplikasi GoPay',
                            description:
                                'Kamu bakal diarahin buat download aplikasinya dulu',
                            redirectCta: 'Ke app Gopay',
                        },
                        gojek: {
                            title: 'Aplikasi Gojek',
                            redirectCta: 'Ke app Gojek',
                        },
                        savePreferenceDescription:
                            'Selalu pakai app yang saya pilih',
                        redirectPaymentDescription:
                            'Mengarahkan ke tahap pembayaran berikutnya',
                        redirectLinkingDescription:
                            'Mengarahkanmu ke tahap berikutnya',
                        redirectPaymentAlert:
                            'Jika tidak ada respon, silakan pilih app di bawah ini untuk melanjukan pembayaran',
                        redirectLinkingAlert:
                            'Jika tidak ada respon, silakan pilih app di bawah ini untuk lanjut menghubungkan',
                        or: 'atau',
                        redirectionDescriptionGopayApp:
                            'Jika tidak ada respon, silakan klik <redirectionLink>di sini.</redirectionLink>',
                        gopayAppOneTimePaymentRedirectionTitle:
                            'Mengarahkanmu ke aplikasi GoPay buat lanjutin pembayaran',
                    },
                    changePreference: {
                        successDescription: 'Preferensi berhasil diubah',
                        errorDescription:
                            'Tidak ada perubahan dalam preferensi aplikasi',
                        redirectDescription:
                            'Mengarahkan Anda kembali ke aplikasi...',
                    },
                    resetPreference: {
                        gopay: {
                            title: 'App default kamu buat pembayaran adalah GoPay',
                            description:
                                'Kalo mau diganti ke Gojek, kamu perlu uninstall aplikasi GoPay dulu. Abis itu, klik tombol di bawah.',
                            cta: 'Ubah app default ke Gojek',
                        },
                        gojek: {
                            title: 'App default kamu buat pembayaran adalah Gojek',
                            description:
                                'Kalo mau diganti ke GoPay, kamu perlu install aplikasi GoPay dulu. Kalo udah diinstal, nanti preferensimu bakal otomatis diganti ke GoPay.',
                            cta: 'Kembali ke app Gojek',
                        },
                        none: {
                            title: 'Preferensi app pembayaran kamu belum ditentuin',
                            description: `Silakan lakukan pembayaran di rekan usaha online buat nentuin preferensi kamu.

                            Karena kamu belum atur preferensi, silakan kembali dulu ke app Gojek.`,
                            cta: 'Kembali ke app Gojek',
                        },
                    },
                    resetPreferenceSuccess: {
                        title: 'App default diganti ke Gojek',
                        description: 'Mengembalikan ke aplikasi default...',
                        buttonTitle: 'Kembali ke app Gojek',
                        buttonDescription:
                            'Klik tombol di bawah kalo gak ada respon',
                    },
                    errors: {
                        404: {
                            title: 'Ada sedikit masalah...',
                            description:
                                'Silakan tutup halaman ini dan coba hubungkan kembali, ya.',
                        },
                        500: {
                            title: 'Ada sedikit masalah, nih',
                            description:
                                'Tenang, kami lagi cariin jalan keluarnya. Coba lagi ya, nanti.',
                        },
                    },
                },
            },
        },
    })
    .catch((error) => {
        if (isProduction()) {
            window.newrelic?.noticeError(error)
        } else {
            console.log('Translation Initialisation Threw error!')
        }
    })
    .then(() => {
        if (!isProduction())
            console.log('Translation Initialisation process completed!')
    })

export default i18n
