import Page from 'components/commons/Page'
import { Modal } from 'components/commons/Modal'
import Description from 'components/commons/Description'
import { useState } from 'react'
import type { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import gopayLogo from 'assets/images/logo-dark.svg'
import Logo from 'components/commons/Logo'

interface ErrorPageProps {
    title: string
    description: string
    image: string
}

const ErrorPage = ({
    title,
    description,
    image,
}: ErrorPageProps): ReactElement => {
    return (
        <>
            <Page>
                <Container>
                    <header
                        style={{
                            borderBottom: '1px solid #d8d8d8',
                            padding: '1rem 1.5rem',
                        }}
                    >
                        <div style={{ width: '67px' }}>
                            <Logo imgSrc={gopayLogo} title="gopay-logo-dark" />
                        </div>
                    </header>
                    <Content>
                        <div style={{ padding: '35px 0 16px' }}>
                            <img
                                data-testid="error-image"
                                alt="error-image-not-found"
                                src={image}
                                width={373}
                                height={220}
                                style={{
                                    margin: '0 auto',
                                    maxWidth: '100%',
                                    maxHeight: '100%',
                                }}
                            />
                        </div>
                        <h4 style={{ margin: '1rem 0' }}>{title}</h4>
                        <Description>{description}</Description>
                    </Content>
                </Container>
                <Footer />
            </Page>
        </>
    )
}

const Container = styled.div`
    display: flex;
    flex: 0 1;
    border: 1px solid #bcc5cf;
    border-radius: 6px;
    margin: 0 auto;
    width: 480px;
    justify-content: center;
    flex-direction: column;
    @media screen and (max-width: 576px) {
        flex: 1 1;
        width: 100%;
        border: none;
    }
`

const Content = styled.div`
    padding: 3.125rem 1.5rem 1.5rem;
    flex-grow: 1;
    @media screen and (min-width: 576px) {
        padding: 3rem 2.5rem 2.25rem;
        min-height: 300px;
    }
`

const Footer = (): ReactElement => {
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
    const [modalReference, setModalReference] = useState<string | undefined>()
    const [modalTitle, setModalTitle] = useState<string | undefined>()
    const { t } = useTranslation()

    const toggle = (): void => {
        setIsModalOpen(!isModalOpen)
    }

    const handleClick = (id: string, value: string, title: string): void => {
        if (value) {
            setModalReference(value)
            setModalTitle(title)
            toggle()
        }
    }

    return (
        <>
            <List>
                <li>
                    <Link
                        onClick={() => {
                            handleClick(
                                'pp',
                                process.env
                                    .REACT_APP_EXTERNAL_LINK_PRIVACY_POLICY,
                                t('labelPrivacyPolicy')
                            )
                        }}
                    >
                        <span>{t('labelPrivacyPolicy')}</span>
                    </Link>
                </li>
                <li>
                    <Link
                        onClick={() => {
                            handleClick(
                                'tnc',
                                process.env
                                    .REACT_APP_EXTERNAL_LINK_TERMS_AND_CONDITIONS,
                                t('labelTermsAndConditions')
                            )
                        }}
                    >
                        <span>{t('labelTermsAndConditions')}</span>
                    </Link>
                </li>
            </List>
            <Modal isOpen={isModalOpen} title={modalTitle} close={toggle}>
                <iframe
                    src={modalReference}
                    style={{ width: '100%', height: '100%', border: 0 }}
                ></iframe>
            </Modal>
        </>
    )
}

const List = styled.ul`
    display: flex;
    justify-content: flex-end;
    list-style: none;
    padding: 16px 0;
    margin: 0;
    width: 480px;
    & li span {
        font-weight: bold;
        font-size: 14px;
    }
    & li:first-child {
        margin-right: 20px;
    }
    @media screen and (max-width: 576px) {
        padding: 24px;
        width: 100%;
    }
`

const Link = styled.a`
    font-weight: bold;
    text-decoration: none;
    cursor: pointer;
`

export default ErrorPage
