export const isProduction = (): boolean => {
    return process.env.NODE_ENV === 'production'
}

export const isDevelopment = (): boolean => {
    return process.env.NODE_ENV === 'development'
}

export const isTest = (): boolean => {
    return process.env.NODE_ENV === 'test'
}

export const asBoolean = (key: any): boolean => {
    return key === 'true'
}
