import { Clickstream } from '@gojek/clickstream-web'
import EventTargetPolyfill from '@mattkrick/event-target-polyfill'
import { asBoolean } from 'helpers/environment'

import { PageEventsConstants } from 'constants/clickStreamEvents'

// Add the polyfill for iOS 12 - 14. Reference: https://go-jek.atlassian.net/wiki/spaces/EP/pages/2515574996/Clickstream+Web+Integration+Manual#Send-events-in-iOS-12---14
window.EventTarget = EventTargetPolyfill

export const initClickStream = (token: string, url: URL): Clickstream => {
    return new Clickstream({
        event: {
            classification: {
                instant: Object.values(PageEventsConstants), // add eventName in this array to send it as QoS0 events. For more: https://go-jek.atlassian.net/wiki/spaces/EP/pages/2515574996/Clickstream+Web+Integration+Manual#Send-QoS0-events
            },
            group: 'web-gopay',
        },
        network: {
            url: new URL(url).toString(),
            headers: new Headers({
                Authorization: `Basic ${token}`,
            }),
            maxRetries: 5,
            timeBetweenTwoRetries: 3000,
            timeToResumeRetries: 30000,
        },
        debug: asBoolean(process.env.REACT_APP_CLICKSTREAM_DEBUG_TOGGLE),
    })
}
