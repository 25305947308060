const theme = {
    colors: {
        brand: '#00880D',
        primary: '#2D2E34',
        background: '#FFFFFF',
        error: '#F3536B',
        link: '#2380fb',
    },
}

export default theme
