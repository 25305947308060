import ErrorBoundary from 'pages/ErrorBoundary'
import React, { lazy } from 'react'
import { createBrowserRouter } from 'react-router-dom'

const OneTimePayment = lazy(
    async () => await import('../pages/AppSelection/OneTimePayment/Container')
)
const TokenisationLinking = lazy(
    async () => await import('../pages/AppSelection/TokenisationLinking')
)
const TokenisationPayment = lazy(
    async () => await import('../pages/AppSelection/TokenisationPayment')
)
const ChangePreference = lazy(
    async () => await import('../pages/ChangePreference')
)
const ResetPreference = lazy(
    async () => await import('../pages/ResetPreference')
)

const routes = createBrowserRouter([
    {
        path: '/',
        element: <div>Hello World</div>,
        errorElement: <ErrorBoundary />,
    },
    {
        path: 'app/merchanttransfer',
        element: <OneTimePayment />,
        errorElement: <ErrorBoundary />,
    },
    {
        path: 'app/tokenization/linking',
        element: <TokenisationLinking />,
        errorElement: <ErrorBoundary />,
    },
    {
        path: 'app/tokenization/payment',
        element: <TokenisationPayment />,
        errorElement: <ErrorBoundary />,
    },
    {
        path: 'app/merchanttransfer/resetCache',
        element: <ChangePreference />,
        errorElement: <ErrorBoundary />,
    },
    {
        path: 'app/reset-preference',
        element: <ResetPreference />,
        errorElement: <ErrorBoundary />,
    },
])

export default routes
