import { useSearchParams } from 'react-router-dom'

type Params = Record<string, string>

const useQueryParams = (): Params => {
    const [searchParams] = useSearchParams()
    return Array.from(searchParams.entries()).reduce<Params>(
        (result: Params, item: [string, string]): any => {
            result[item[0]] = item[1]
            return result
        },
        {}
    )
}

export default useQueryParams
