import React from 'react'
import styled from 'styled-components'

const Logo = ({
    imgSrc,
    title,
    size,
}: {
    imgSrc: any
    title: string
    size?: number
}) => {
    return (
        <LogoContainer>
            <img
                src={imgSrc}
                data-testid="gopay-logo"
                alt={title}
                width={size ?? '100%'}
                height={size}
            />
        </LogoContainer>
    )
}

const LogoContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    @media screen and (max-width: 768px) {
        margin-bottom: 0;
    }
`

export default Logo
