export default class GenericError extends Error {
    title: string
    description: string
    image: string

    constructor(
        message: string,
        title: string,
        description: string,
        image: string
    ) {
        super(message)
        this.title = title
        this.description = description
        this.image = image
    }
}
