export const PageEventsConstants = {
    GOPAY_REDIRECTION_SAVE_PREFERENCE_TOGGLE_ON:
        'gopay-redirection-save-preference-toggle-on',
    GOPAY_REDIRECTION_SAVE_PREFERENCE_TOGGLE_OFF:
        'gopay-redirection-save-preference-toggle-off',
    GOPAY_REDIRECTION_PAGE_LOADED: 'gopay-redirection-page-loaded',
    GOPAY_REDIRECTION_GOJEK_APP_SELECTED:
        'gopay-redirection-gojek-app-selected',
    GOPAY_REDIRECTION_GOPAY_APP_SELECTED:
        'gopay-redirection-gopay-app-selected',
    GOPAY_REDIRECTION_RESET_PREFERENCE: 'gopay-redirection-reset-preference',
    GOPAY_REDIRECTION_PAGE_USER_REDIRECTED:
        'gopay-redirection-page-user-redirected',
    GOPAY_REDIRECTION_PAGE_SELECTION: 'gopay-redirection-page-selection',
    GOPAY_REDIRECTION_PAGE_LOADING_ERROR:
        'gopay-redirection-page-loading-error',
    GOPAY_REDIRECTION_PAGE_GOPAY_CTA_CLICKED:
        'gopay-redirection-page-gopay-cta-clicked',
    GOPAY_REDIRECTION_PAGE_GOJEK_CTA_CLICKED:
        'gopay-redirection-page-gojek-cta-clicked',
    GOPAY_ONLY_REDIRECTION_PAGE_SELECTION:
        'gopay-only-redirection-page-selection',
    GOPAY_ONLY_REDIRECTION_PAGE_USER_REDIRECTED:
        'gopay-only-redirection-page-user-redirected',
    GOPAY_ONLY_REDIRECTION_GOPAY_APP_SELECTED:
        'gopay-only-redirection-gopay-app-selected',
}
