import React, { useMemo } from 'react'
import type { ReactElement } from 'react'
import Router from 'router'
import { ThemeProvider } from 'styled-components'
import theme from 'theme'
import { GlobalStyles } from 'theme/GlobalStyles'
import './App.css'
import { EventsContext } from 'contexts'
import { type Clickstream } from '@gojek/clickstream-web'
import { initClickStream } from 'utils/clickstream'

const App = (): ReactElement => {
    // initialize click stream of user events
    const clickStream = useMemo((): Clickstream => {
        return initClickStream(
            process.env.REACT_APP_CLICKSTREAM_TOKEN,
            process.env.REACT_APP_CLICKSTREAM_URL
        )
    }, [])

    return (
        <ThemeProvider theme={theme}>
            <EventsContext.Provider value={clickStream}>
                <GlobalStyles />
                <div className="App">
                    <Router />
                </div>
            </EventsContext.Provider>
        </ThemeProvider>
    )
}

export default App
