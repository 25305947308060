import type { ReactNode, ReactPortal } from 'react'
import { createPortal } from 'react-dom'
import styled from 'styled-components'
import closeIcon from 'assets/images/close-icon.svg'

export interface ModalProps {
    children: ReactNode
    title?: string
    isOpen: boolean
    close: () => void
}

export const Modal = ({
    children,
    isOpen,
    title,
    close,
}: ModalProps): ReactPortal | null => {
    const containerEle = document.getElementById('modal-root')
    if (!containerEle) return null

    return createPortal(
        <>
            {isOpen ? (
                <Container
                    onClick={() => {
                        close()
                    }}
                >
                    <ModalBody
                        onClick={(e) => {
                            e.stopPropagation()
                        }}
                    >
                        <ModalHeader>
                            <ModalTitle>{title}</ModalTitle>
                            <CloseButton
                                src={closeIcon}
                                onClick={() => {
                                    close()
                                }}
                                width={24}
                                height={24}
                            ></CloseButton>
                        </ModalHeader>
                        <ModalContent>{children}</ModalContent>
                        <ModalFooter></ModalFooter>
                    </ModalBody>
                </Container>
            ) : null}
        </>,
        containerEle
    )
}

const Container = styled.div`
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.35);
    display: flex;
    justify-content: center;
    align-items: center;
`

const ModalBody = styled.div`
    display: flex;
    flex-direction: column;
    width: 80vw;
    height: 90vh;
    background-color: #fff;
    padding: 0.4em;
    box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.2);
    border-radius: 0.5rem;
    overflow: hidden;
    @media screen and (max-width: 575px) {
        width: 100vw;
        height: 100vh;
        max-height: 100vh;
        border-radius: 0;
    }
`

const ModalHeader = styled.div`
    position: relative;
    display: flex;
    margin-top: 0.5rem;
    margin-bottom: 1rem;
`

const ModalTitle = styled.span`
    font-size: 1.2rem;
    flex: 1;
    text-align: center;
    font-weight: 600;
`

const ModalContent = styled.div`
    flex: 1;
    border-radius: 0.25rem;
    overflow: auto;
`

const ModalFooter = styled.div``
const CloseButton = styled.img`
    position: absolute;
    top: 0;
    right: 0.5rem;
    opacity: 0.35;
    &:hover {
        opacity: 1;
    }
`
